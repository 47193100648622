<template>
<!-- checking by status ready -->
<b-col sm="8" :key="componentKey" v-if="productItem && productItem.characteristic_id">
    <b-row v-for="(stage, index) in productItem.stages" :key="index" class="mt-2 pt-2 pb-2 bg-light">
        <b-col sm="2">
            <strong>{{ stage.name }}</strong>
        </b-col>
        <b-col sm="5">
            <h5>{{ $t('photo') }}</h5>
            <div class="image-area">
                <img v-if="stage.photo" :src="stage.photo | image" class="img-fluid">
                <img v-else src="@/assets/images/no-image.jpg" class="img-fluid">
                <button v-if="stage.photo" @click="removePhoto(stage.id)" type="button" class="remove-image btn btn-sm btn-danger" style="display: inline;" :disabled="!editOn"><fa icon="trash" /></button>
            </div>
            <b-form-file v-if="!stage.photo" @change="changeStagePhoto($event, stage.id)" v-model="stage.photo"></b-form-file>
        </b-col>
        <b-col sm="5" v-if="productItem.product_id">
            <h6>{{ $t('layout') }}</h6>
            <div class="image-area">
                 <img v-if="stage.photo_layout" :src="stage.photo_layout | image" class="img-fluid">
                 <img v-else src="@/assets/images/no-image.jpg" class="img-fluid">
                <button v-if="stage.photo_layout" @click="removePhotoLayout(stage.id)" type="button" class="remove-image btn btn-sm btn-danger" style="display: inline;" :disabled="!editOn"><fa icon="trash" /></button>
            </div>
            <b-form-file v-if="!stage.photo_layout" @change="changeStagePhotoLayout($event, stage.id)" v-model="stage.photoLayout"></b-form-file>
        </b-col>
    </b-row>
</b-col>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
export default {
    data: () => ({
        componentKey: 0
    }),
    props: {
        productId: null
    },
    computed: {
        ...mapGetters({
            orderData: 'order/order',
            editOn: 'order/editProductsData'
        }),
        productItem () {
            if ((this.orderData.products || []).length > 0) {
                const data = this.orderData.products.find(p => p.id === this.productId)
                if (typeof data !== 'undefined') {
                    return data
                }
            }
        }
    },
    methods: {
        ...mapActions({
            setStage: 'order/setStage',
            addStageToProduct: 'order/addStage',
            removeStageFromProduct: 'order/removeStage'
        }),
        forceRerender () {
            this.componentKey += 1
        },
        changeStagePhoto (e, stageId) {
            this.uploadImage(e.target.files[0]).then(image => {
                const stage = this.productItem.stages.find(s => s.id === stageId)
                if (typeof stage !== 'undefined') {
                    stage.photo = image
                    stage.photoBlob = process.env.VUE_APP_ENDPOINT_URL + image
                    this.$nextTick(() => {
                        this.forceRerender()
                    })
                }
            })
        },
        changeStagePhotoLayout (e, stageId) {
            this.uploadImage(e.target.files[0]).then(image => {
                const stage = this.productItem.stages.find(s => s.id === stageId)
                if (typeof stage !== 'undefined') {
                    console.log(image)
                    stage.photo_layout = image
                    stage.photoLayoutBlob = process.env.VUE_APP_ENDPOINT_URL + image
                    this.$nextTick(() => {
                        this.forceRerender()
                    })
                    console.log(stage)
                }
            })
        },
        removePhoto (stageId) {
            const stage = this.productItem.stages.find(s => s.id === stageId)
            if (typeof stage !== 'undefined') {
                this.removeImage(stage.photo)
                stage.photo = null
                stage.photoBlob = null
                this.$nextTick(() => {
                    this.forceRerender()
                })
            }
        },
        removePhotoLayout (stageId) {
            const stage = this.productItem.stages.find(s => s.id === stageId)
            if (typeof stage !== 'undefined') {
                this.removeImage(stage.photoLayout)
                stage.photo_layout = null
                stage.photoLayoutBlob = null
                this.$nextTick(() => {
                    this.forceRerender()
                })
            }
        },
        async uploadImage (file) {
            this.$root.$refs.loading.start()
            const formData = new FormData()
            formData.append('image', file)
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const config = { headers: { 'Content-Type': 'multipart/form-data' } }
            const { data } = await axios.post(apiUrl + 'order/image/upload', formData, config)
            .catch(errors => console.log(errors))
            this.$root.$refs.loading.finish()
            return data
        },
        async removeImage (file) {
            this.$root.$refs.loading.start()
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await axios.post(apiUrl + 'order/image/remove', { image: file })
            this.$root.$refs.loading.finish()
        }
    }
}
</script>
<style scoped>
.image-area {
  position: relative;
  background: #3e3e3e;
}
.remove-image {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
}
</style>
